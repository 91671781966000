// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---packages-gatsby-theme-twentynineteen-src-templates-page-index-js": () => import("./../../packages/gatsby-theme-twentynineteen/src/templates/page/index.js" /* webpackChunkName: "component---packages-gatsby-theme-twentynineteen-src-templates-page-index-js" */),
  "component---packages-gatsby-theme-twentynineteen-src-templates-users-archive-js": () => import("./../../packages/gatsby-theme-twentynineteen/src/templates/users/archive.js" /* webpackChunkName: "component---packages-gatsby-theme-twentynineteen-src-templates-users-archive-js" */),
  "component---packages-gatsby-theme-twentynineteen-src-templates-categories-archive-js": () => import("./../../packages/gatsby-theme-twentynineteen/src/templates/categories/archive.js" /* webpackChunkName: "component---packages-gatsby-theme-twentynineteen-src-templates-categories-archive-js" */)
}

